<template>
  <div>
    <div
      v-if="isShow"
      class="application-wrap"
      style="min-height: 500px"
    >
      <button
        class="btn-close"
        @click.stop="close(false)"
      >
        닫기
      </button>
      <h1>개인정보 수집·이용 안내</h1>
      <p class="s-title mgt20">
        이벤트 경품 지급을 위해 개인정보를 입력해 주세요.
      </p>
      <h1 class="mgt20">
        [필수] 이벤트 참여자 개인정보
      </h1>
      <div class="aw-tb">
        <table>
          <colgroup>
            <col style="width:128px ">
            <col style="width:auto ">
          </colgroup>
          <tr>
            <th>이름</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="inputName"
                  type="text"
                  readonly
                  disabled
                  placeholder="이름 입력"
                >
              </div>
            </td>
          </tr>
          <tr>
            <th>휴대폰번호</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="inputPhoneNum"
                  type="text"
                  :disabled="isVerified"
                  placeholder="'-'를 제외하고 숫자만 입력"
                >
              </div>
            </td>
          </tr>
        </table>
      </div>

      <p class="txt-red">
        ※ 제출하신 개인정보는 수정이 불가능하며, 이벤트 경품은 입력하신 휴대폰번호로 즉시 발송됩니다.
      </p>

      <div class="agree-box collecting-type">
        <dl class="mgt0">
          <dt>[필수] 개인정보 수집 및 이용 동의</dt>
          <dd>
            <div>
              <ol>
                <li><span>1.</span>수집 이용 목적 : 이벤트 보상 지급</li>
                <li><span>2.</span>수집 이용 항목 : 이름, 휴대폰번호</li>
                <li><span>3.</span>보유 및 이용 기간 : 보상지급일로부터 3개월까지</li>
                <li><span>4.</span>개인정보 수집 및 이용 동의에 거부할 수 있으나, 거부할 경우 이벤트 보상이 지급되지 않습니다.</li>
              </ol>
            </div>
          </dd>
        </dl>
      </div>

      <div class="checkbox-wrap mgt20">
        <input
          id="che2"
          v-model="isPrivacyConfirm"
          type="checkbox"
          class="application-type"
          name="agreeType"
        >
        <label
          for="che2"
          class="f16"
        >개인정보 수집 및 이용에 동의합니다.</label>
      </div>

      <div class="btn-wrap">
        <button
          class="btn-application"
          @click.stop="submit"
        >
          동의하기
        </button>
      </div>
    </div>

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserLoginInfo } from '@/common/userInfo/service'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      inputName: null,
      inputPhoneNum: null,
      isVerified: false,
      isPrivacyConfirm: false,
      isShow: false,
    }
  },

  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'account/getName',
      phoneNum: 'account/getPhoneNum',
      ysrUserName: 'infoData/getUserName',
      medicalDept: 'infoData/getMedicalDept',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
  },

  watch: {
    shaEnLicenseNum() {
      if (this.join === null) getUserLoginInfo()
    },
  },

  created() {
    // 회원여부 조회
    if (this.join === null) getUserLoginInfo()
  },

  methods: {
    async call() {
      if (this.join) {
        /// / 회원
        if (this.userName) {
          /// / 로그인 상태
          this.inputName = this.userName
        }
      } else {
        /// / 비회원
        this.inputName = this.ysrUserName
      }

      if (this.phoneNum && this.phoneNum.length > 0) {
        this.isVerified = true
        this.inputPhoneNum = (this.phoneNum).replaceAll('-', '')
      }

      this.isShow = true
    },

    submit() {
      if (!this.inputName) {
        this.showAlertDialog('이름을 입력해 주세요.')
        return
      }
      if (!this.inputPhoneNum) {
        this.showAlertDialog('휴대폰번호를 입력해 주세요.')
        return
      }
      const phoneNumberRegex = /^010\d{8}$/
      if (!this.inputPhoneNum.match(phoneNumberRegex)) {
        this.showAlertDialog('입력하신 휴대전화번호가 올바르지 않습니다.')
        return
      }
      if (!this.isPrivacyConfirm) {
        this.showAlertDialog('개인정보 수집 및 이용에 동의하셔야 경품이 지급됩니다.')
        return
      }

      // 이름, 번호 전달
      // this.$parent.userName = this.userName
      this.$parent.userPhoneNumber = this.inputPhoneNum

      this.close(true)
    },

    close(isConfirm) {
      this.isShow = false

      this.$emit('close', isConfirm)
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  margin: 0 auto;
}

area:hover {
  cursor: pointer;
}

.event-board {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

div.aw-tb table tr th {
  background: transparent;
  border: none;
  padding: 0px;
}

div.aw-tb table tr td {
  padding: 8px 0px;
}
</style>
